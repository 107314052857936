import React from "react"
import {
  Layout,
  Parallax,
  NewsletterSection,
} from "@components/layout"
import { Breadcrumbs } from "@components/shared"

import { PostItem,} from "@components/blog"

import { posts__wrapp } from "./styles/categories.module.scss"

const Posts = ({ posts }) => {
  // const shuffledPosts = posts.sort(() => Math.random() - 0.5)

  return (
    <React.Fragment>
      {/* 			
				<h4>Data dla posts:{JSON.stringify(posts, null, 4)}</h4>
			 */}
      {posts.map((el, k) => (
        <PostItem {...el} key={k} />
      ))}
    </React.Fragment>
  )
}

const SingleCategory = ({ pageContext }) => {
  const { posts, page } = pageContext
  return (
    <Layout seo={page.seo} siteMetaData={page.siteMetaData}>
      <Parallax height={400} image={page.featuredImage} title={ page.name  } subtitle={ page.description }>
      </Parallax>
      <Breadcrumbs
        elements={[
          {
            label: "Blog",
            url: `/blog/`,
          },
          {
            label: page.name,
            url: `/blog/${page.slug}/`,
          },
        ]}
      />
      <div className={posts__wrapp}>
        <Posts posts={posts} />
      </div>
      <NewsletterSection />
    </Layout>
  )
}

export default SingleCategory
